<template>
  <v-container fill-height fluid class="grey">
    <v-layout flex align-center justify-center v-if="!authData">
      <v-flex xs12 sm6 md5 lg4 class="card-form">
        <v-card>
          <v-card-title class="primary--text justify-center">
            Assessment<br />management<br />system
          </v-card-title>
          <v-card-subtitle class="dark--text mt-2">
            by
            <a class="tab-link" href="https://mozlab.ru/" target="_blank"
              >mozlab.ru</a
            >
          </v-card-subtitle>
          <v-card-text>
            <v-form
              ref="form"
              v-model="isValidForm"
              style="max-width: 310px"
              class="ma-auto"
            >
              <v-text-field
                outlined
                label="Введите ваш логин"
                placeholder=" "
                v-model="user.email"
                :rules="[v => !!v || 'Логин не может быть пустым.']"
                required
              ></v-text-field>
              <v-text-field
                outlined
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                label="Введите ваш пароль"
                placeholder=" "
                v-model="user.password"
                :rules="[v => !!v || 'Пароль не может быть пустым.']"
                :type="showPassword ? 'text' : 'password'"
                @click:append="toggleShowPassword"
                required
              ></v-text-field>
              <div class="d-flex">
                <v-checkbox
                  class="mt-0 text-body-2"
                  v-model="privatePolicy"
                  hide-details
                  :rules="[v => !!v || '']"
                  label=""
                  required
                ></v-checkbox>
                <p>
                  Даю
                  <span
                    class="tab-link"
                    @click="switchToggle(true, 'agreement')"
                    >согласие на обработку персональных данных</span
                  >,
                  <span class="tab-link" @click="switchToggle(true, 'video')"
                    >согласие на съемку (в случае проведения видеозаписи)</span
                  >принимаю условия
                  <span class="tab-link" @click="switchToggle(true, 'terms')"
                    >Пользовательского соглашения</span
                  >
                  и подтверждаю, что ознакомился с
                  <span class="tab-link" @click="switchToggle(true, 'policy')"
                    >Политикой конфиденциальности</span
                  >
                </p>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions class="mt-3">
            <v-btn
              :loading="loading"
              :disabled="!isValidForm"
              @click="submit"
              class="white--text primary"
              style="-webkit-text-fill-color: white"
              >Войти</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
      <modal :dialog="toggle" :width="800">
        <div
          :style="{
            maxHeight: '60%',
            overflowY: 'auto',
            textAlign: 'justify',
          }"
        >
          <PrivatePolicy v-show="toggle.view == 'policy'" />
          <TermsOfUse v-show="toggle.view == 'terms'" />
          <ConsentVideoRecording v-show="toggle.view == 'video'" />
          <PersonalDataAgreement v-show="toggle.view == 'agreement'" />
        </div>
      </modal>
    </v-layout>
    <v-layout
      v-if="authData && roles.length > 1 && !roleChanged"
      flex
      align-center
      justify-center
    >
      <v-flex xs12 sm6 md5 lg4 class="card-form">
        <v-card class="d-flex flex-column align-center pa-5">
          <v-card-title class="justify-center mb-5">Выберите роль</v-card-title>
          <v-btn
            class="mb-5"
            :style="{ width: '100%' }"
            color="primary"
            depressed
            v-for="item in roles"
            :key="item.name"
            @click="changeRole(item)"
          >
            {{ item.title }}
          </v-btn>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { ref, reactive, computed, onMounted } from "@vue/composition-api";
import { useModalToggle } from "@/use/helpers";
import PrivatePolicy from "@/components/policy/PrivatePolicy";
import TermsOfUse from "@/components/policy/TermsOfUse";
import ConsentVideoRecording from "@/components/policy/ConsentVideoRecording";
import PersonalDataAgreement from "@/components/policy/PersonalDataAgreement";

export default {
  setup(_, { refs, root }) {
    const store = root.$store;

    const { toggle, switchToggle } = useModalToggle();

    const authData = computed(() => store.getters.getAuthData);

    if (authData.value) {
      if (
        authData.value.roles.includes(
          root.$router.history.current.name.toUpperCase()
        )
      )
        store.commit("changeRole", {
          name: root.$router.history.current.name.toUpperCase(),
          view: root.$router.history.current.name,
        });
      else
        root.$router.push(
          `/${store.getters.getRoles[authData.value.roles[0]].view}`
        );
    }

    const roles = computed(() => store.getters.getRoleList);

    const showPassword = ref(false);
    const toggleShowPassword = () => (showPassword.value = !showPassword.value);

    const isValidForm = ref(true);

    const user = reactive({
      email: null,
      password: null,
    });
    const privatePolicy = false;

    const loading = ref(false);

    const roleChanged = ref(false);
    const changeRole = role => {
      roleChanged.value = true;
      store.commit("changeRole", role);
    };

    const submit = async () => {
      Object.keys(user).forEach(key => {
        user[key] = user[key].trim();
      });

      if (refs.form.validate()) {
        await root.$store.dispatch("login", user);
        if (store.getters.getAuthData?.roles.length === 1)
          root.$router.push(`/${store.getters.getAuthData.roles[0]}`);
      }
    };

    onMounted(() => localStorage.removeItem("lastTab"));

    return {
      toggle,
      switchToggle,
      showPassword,
      toggleShowPassword,
      isValidForm,
      user,
      privatePolicy,
      loading,
      submit,
      authData,
      roles,
      roleChanged,
      changeRole,
    };
  },
  components: {
    PrivatePolicy,
    TermsOfUse,
    ConsentVideoRecording,
    PersonalDataAgreement,
  },
};
</script>
